import React, { useState, useEffect } from 'react';

const Patients = () => {
    const [patients, setPatients] = useState([]);
    const [latestPatient, setLatestPatient] = useState(null);
    const [audio] = useState(new Audio('alert.mp3')); // Verifica la ruta

    useEffect(() => {
        audio.preload = 'auto';
        audio.load();

        const eventSource = new EventSource('https://saludquemchi.online/events');

        eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (Array.isArray(data)) {
                audio.play().catch(err => console.error('Error al intentar reproducir el sonido:', err));
                setPatients(data);
                setLatestPatient(data[0]);

                // Asegúrate de que latestPatient tenga boxName y hallName
                setTimeout(() => {
                    if (data[0] && data[0].boxName && data[0].hallName) {
                        readPatientInfo(data[0].name_patient, data[0].boxName, data[0].hallName);
                    } else {
                        console.warn('boxName o hallName no están disponibles en los datos:', data[0]);
                    }
                }, 1000); // 1 segundo de retraso
            } else {
                console.log('Los datos recibidos no son un array:', data);
            }
        };

        return () => {
            eventSource.close();
        };
    }, [audio]);

    const getRandomSpanishVoice = () => {
        let voices = window.speechSynthesis.getVoices();
        // Filtra las voces que tienen "es-" y excluyen "es-ES" (España)
        let latinAmericanVoices = voices.filter(voice => 
            voice.lang.startsWith('es-') && voice.lang !== 'es-ES'
        );
        if (latinAmericanVoices.length > 0) {
            let randomIndex = Math.floor(Math.random() * latinAmericanVoices.length);
            return latinAmericanVoices[randomIndex];
        } else {
            return null;// No hay voces en español disponibles
        }
    };

    const readPatientInfo = (name, boxName, hallName) => {
        if ('speechSynthesis' in window) {
            const text = `Atención, paciente ${name}, presentarse en ${hallName}, ${boxName}`;
            const utterance = new SpeechSynthesisUtterance(text);
            utterance.voice = getRandomSpanishVoice();
            utterance.rate = 0.8;
            window.speechSynthesis.speak(utterance);
        } else {
            console.warn('La síntesis de voz no está soportada en este navegador.');
        }
    };

    useEffect(() => {
        if (window.speechSynthesis.getVoices().length === 0) {
            window.speechSynthesis.onvoiceschanged = () => {
                getRandomSpanishVoice(); // Inicializa la carga de voces
            };
        }
    }, []);

    return (
        <div>
            {latestPatient && (
                <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <h2>Último Paciente</h2>
                    <h1 style={{ fontSize: '6rem' }}>{latestPatient.name_patient}</h1>
                    <h1 style={{ fontSize: '3rem' }}>{latestPatient.hallName} - {latestPatient.boxName}</h1>
                    <h1 style={{ fontSize: '3rem' }}>{latestPatient.hour}</h1>
                </div>
            )}
            <table style={{ width: '80%', borderCollapse: 'collapse', border: '1px solid #ddd', margin: '0 auto' }}>
                <thead>
                    <tr>
                        {/* <th style={{ border: '1px solid #000', padding: '8px', fontSize: '1.2rem' }}>ID</th> */}
                        <th style={{ border: '1px solid #000', padding: '8px', fontSize: '1.2rem' }}>Nombre</th>
                        <th style={{ border: '1px solid #000', padding: '8px', fontSize: '1.2rem' }}>Fecha</th>
                        <th style={{ border: '1px solid #000', padding: '8px', fontSize: '1.2rem' }}>Hora</th>
                        <th style={{ border: '1px solid #000', padding: '8px', fontSize: '1.2rem' }}>Box</th>
                        <th style={{ border: '1px solid #000', padding: '8px', fontSize: '1.2rem' }}>Pasillo</th>
                    </tr>
                </thead>
                <tbody>
                    {patients.map(patient => (
                        <tr key={patient.id}>
                            {/* <td style={{ border: '1px solid #000', padding: '8px', fontSize: '1.2rem' }}>{patient.id}</td> */}
                            <td style={{ border: '1px solid #000', padding: '8px', fontSize: '1.2rem' }}>{patient.name_patient}</td>
                            <td style={{ border: '1px solid #000', padding: '8px', fontSize: '1.2rem' }}>{formatDate(patient.date)}</td>
                            <td style={{ border: '1px solid #000', padding: '8px', fontSize: '1.2rem' }}>{patient.hour}</td>
                            <td style={{ border: '1px solid #000', padding: '8px', fontSize: '1.2rem' }}>{patient.boxName}</td>
                            <td style={{ border: '1px solid #000', padding: '8px', fontSize: '1.2rem' }}>{patient.hallName}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    function formatDate(dateString) {
        const [year, month, day] = dateString.split('-');
        return `${day}-${month}-${year}`;
    }
};

export default Patients;
