// src/App.js
import React from 'react';
import Patients from './Patients';

function App() {
  return (
    <div className="App">
      <Patients />
    </div>
  );
}

export default App;
